<template>
  <portal to="left-app-bar">
    <v-toolbar
      v-if="sidebarEntry"
      floating
      :dark="!dialog"
      :class="{prominent: prominent ? 'prominent' : '', 'toolbar-floating' : !dialog}"
      class="toolbar-items"
      :height="70"
      :elevation="0"
    >
      <v-icon
        large
        class="pr-0"
        :class="sidebarEntry.color + '--text'"
      >
        {{ sidebarEntry.icon }}
      </v-icon>


      <v-toolbar-title class="hidden-md-and-down">
        <span
          :class="{'grey--text': subTitle !== null}"
          v-html="sidebarEntry.title"
        ></span>
        <span
          v-if="subTitle"
          v-html="' | ' + subTitle"
        ></span>
      </v-toolbar-title>

      <v-divider
        class="pr-2"
        vertical
      ></v-divider>

      <slot></slot>
    </v-toolbar>
  </portal>
</template>

<script>
import sidebar from "@/shared/config/sidebar.ts"
import {Portal} from "portal-vue";

export default {
  name: 'ToolbarFloating',
  components: {
    Portal,
  },
  props: {
    subTitle: {
      type: String,
      default: null,
    },
    prominent: {
      type: Boolean,
      default: false,
    },
    toolbarId: {
      type: String,
      required: true,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sidebarEntry() {
      return sidebar.find(el => el.target === this.toolbarId)
    },
  },
}
</script>


<style lang="scss">
.toolbar-floating {
  transition: all .2s ease-in-out;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #424242;
}

.toolbar-floating {
  &.theme--dark.v-toolbar {
    background: transparent;
  }

  .v-toolbar__content {
    padding-left: 0;
    padding-right: 0;

    > * {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }

    .v-input {
      max-width: 320px;
    }
  }

  .v-select__selection {
    white-space: nowrap;
  }
}
</style>




































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'ButtonAdd',
  props: {
    label: {
      type: String,
      default: 'Add',
    },
    text: {
      type: Boolean,
      default: true,
    },
    display: {
      type: String,
      default: 'inline',
    },
    dark: {
      type: Boolean,
      default: false,
      required: false,
    },
    color: {
      type: String,
      default: 'accent',
      required: false,
    },
    icon: {
      type: String,
      default: 'add',
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
